// i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  }).then(() => {
    console.log('i18next initialized successfully');
  })
  .catch((error) => {
    console.error('Error initializing i18next:', error);
  });

export default i18n;
