import React from 'react';
import "../Loading_Spinner/LoadSpinner.css";

function LoadSpinner() {
 

    return (
      <div className="loading-spinner">
      {/* Your loading spinner or loading screen JSX here */}
      <div className="spinner"></div>
      <p>Loading...</p>
    </div>
      );
    }

export default LoadSpinner