import React from 'react'

function PageNotFound() {
  return (
    <div style={{justifyContent:"center",alignItems:"center",display:"flex",height:"98vh"}}>
        <h1>404 Page not found</h1>
        </div>
  )
}

export default PageNotFound;